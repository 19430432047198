/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import SEO from "../components/seo"
import useScript from "../hooks/use-script"
import { Link, withPrefix } from "gatsby"
import Layout from "../components/layout"
const Legal = (): JSX.Element => {
  useScript(withPrefix("js/show-on-scroll.js"))
  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        url: "https://documentesign.com/legal/",
        name:
          "Online Electronic Signature Solutions to Digitize Legal Agreements",
        isPartOf: {
          "@type": "WebSite",
          "@id": "https://documentesign.com/legal/#website",
        },
        datePublished: "2021-02-16T07:56:57+00:00",
        dateModified: "2021-04-28T16:44:56+00:00",
        description:
          "Document eSign an electronic signature services empowers the legal department to improve compliance and efficiency in your legal processes",
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://documentesign.com/legal/"],
          },
        ],
      },
    ],
  }
  return (
    <>
      <Layout rawData={{ className: "sticky-header" }}>
        <SEO
          title=" Online Electronic Signature Solutions to Digitize Legal Agreements"
          description="Document eSign an electronic signature services empowers the legal department to improve compliance and efficiency in your legal processes"
          schemaMarkup={schema}
        />
        <div className="intro legal-intro bg-blue-light ani-in show-on-scroll">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 py-4 py-lg-5 ani-top">
                <h1 className="pt-1">
                  eSignature solutions to digitize agreement services
                </h1>
                <p className="mt-4 pt-1 fw-regular">
                  Collect legally binding documents and store important
                  applications and forms to improve compliance and efficiency in
                  your legal processes. Retrieve electronically stored and
                  signed documents online and forget about any paperwork.
                </p>
                <a
                  href="https://app.documentesign.com/auth/signup"
                  target="_blank"
                  className="btn btn-dark mt-4 mb-2 px-md-5"
                >
                  Get started
                </a>
                <br />
                <small className="text-muted">
                  <strong>Free forever,</strong> No credit card required
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lights">
          <div className="container py-5 mt-lg-4">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <img
                    data-src={withPrefix("images/reduce-paperwork.jpg")}
                    className="card-img-top lazyload"
                    alt="reduce paperwork"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-scroll"></i>
                    </div>
                    <h4>Signing a legal form in seconds</h4>
                    <p className="card-text">
                      Send forms or agreements to multiple signers to collect
                      legally-binding eSignatures, in the order of signing. Get
                      notified when the form is processed. Store and retrieve
                      documents that are saved in the cloud storage for easy
                      access. Concentrate more on research and briefings and
                      forget about any paperwork that reduces productivity.
                      Collect eSignatures online and complete documentation
                      overnight without waiting for any emails and faxes.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <img
                    data-src={withPrefix("images/device-integrations.jpg")}
                    className="card-img-top lazyload"
                    alt="device integrations"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-mobile-alt"></i>
                    </div>
                    <h4>Device integrations for lesser hassles</h4>
                    <p className="card-text">
                      Allow your mobile device to empower prenuptial legal
                      documents, or process a power of attorney, in a single
                      swipe of your cursor. Cut down hassles for your clients by
                      processing your legal documents faster and enabling
                      clients to sign documents from anywhere, anytime from
                      Android or iOS. Keep the process moving for your clients
                      and make paperwork less burdensome for them.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <img
                    data-src={withPrefix("images/compliance-security.jpg")}
                    className="card-img-top lazyload"
                    alt="compliance security"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-shield-alt"></i>
                    </div>
                    <h4>Compliance and security</h4>
                    <p className="card-text">
                      Process all legal documents with Document eSign and
                      protect with advanced encryption and two-factor
                      authentication safeguards.{" "}
                      <Link to="/document-sharing-tracking/">
                        Track and sign
                      </Link>{" "}
                      all the completed documents from anywhere any time. Keep
                      your practise away from all malpractices by tracking and
                      maintaining audit trails of all your signed agreements and
                      consent forms. Through a secure app like Document eSign
                      keeps sensitive documents safe from any mishaps.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <img
                    data-src={withPrefix("images/automated-workflow.jpg")}
                    className="card-img-top lazyload"
                    alt="automated workflow"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-money-check-alt"></i>
                    </div>
                    <h4>Automated Workflow for easy payment collection</h4>
                    <p className="card-text">
                      Collaborate and create your documents seamlessly by
                      integrating to your existing workflow with the APIs.
                      Collect payments easily from your clients using fillable
                      forms. Automate your workflow into an eSign solution and
                      get notified every time a payment is made. Track and
                      maintain all the payment related data from any
                      internet-connected device.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <img
                    data-src={withPrefix(
                      "images/documents-look-professional.jpg"
                    )}
                    className="card-img-top lazyload"
                    alt="documents look professional"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-file-alt"></i>
                    </div>
                    <h4>Make your Documents Look Professional</h4>
                    <p className="card-text">
                      Document eSign enables you to collect and create multiple
                      legal forms for different contents with embedding fields
                      for your clients to provide accurate datas. Make your
                      documents look professional by designating required fields
                      and guide the signers to your documents. Get rid of paper
                      forms and bulky{" "}
                      <Link to="/free-pdf-esign-online/"> PDF documents </Link>{" "}
                      and switch to a convenient mobile-friendly intake form
                      that has intuitive way of getting deals closed.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <img
                    data-src={withPrefix("images/maintain-lawfulness.jpg")}
                    className="card-img-top lazyload"
                    alt="maintain lawfulness"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-gavel"></i>
                    </div>
                    <h4>Maintain lawfulness and court admissibility</h4>
                    <p className="card-text">
                      An Document eSign agreement is tamper-proof. With paper
                      based agreements and court-admissible certificates, and
                      manage and maintain the audit trail on your activities on
                      your documents.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Legal
